import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let Card = class Card extends Vue {
    mounted() { }
    get hasContentFooterSlot() {
        return !!this.$slots['content-footer'];
    }
    get hasFooterSlot() {
        return !!this.$slots['footer'];
    }
    get hasButtonSlot() {
        return !!this.$slots['button'];
    }
    get hasIconSlot() {
        return !!this.$slots['icon'];
    }
};
__decorate([
    Prop()
], Card.prototype, "image", void 0);
__decorate([
    Prop()
], Card.prototype, "copyright", void 0);
__decorate([
    Prop({ default: 'medium' })
], Card.prototype, "size", void 0);
__decorate([
    Prop()
], Card.prototype, "href", void 0);
__decorate([
    Prop({ default: false })
], Card.prototype, "offerBadge", void 0);
__decorate([
    Prop({ default: false })
], Card.prototype, "isTravelRelated", void 0);
Card = __decorate([
    Component
], Card);
export default Card;
