import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
let marked = require("marked");
import DOMPurify from "dompurify";
let MarkdownFormatter = class MarkdownFormatter extends Vue {
    mounted() {
        document.querySelectorAll('.marked-content a')
            .forEach(function (elem) {
            elem.setAttribute('target', '_blank');
        });
    }
    get markedContent() {
        return marked(DOMPurify.sanitize(this.content, { ALLOWED_ATTR: ['target'] }, { ADD_ATTR: ['target'] }));
    }
};
__decorate([
    Prop()
], MarkdownFormatter.prototype, "content", void 0);
MarkdownFormatter = __decorate([
    Component
], MarkdownFormatter);
export default MarkdownFormatter;
