import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
let Pagination = class Pagination extends Vue {
    mounted() {
    }
    pagesNumber() {
        if (!this.pagination.to) {
            return [];
        }
        let from = this.pagination.current_page - this.offset;
        if (from < 1) {
            from = 1;
        }
        let to = from + (this.offset * 2);
        if (to >= this.pagination.last_page) {
            to = this.pagination.last_page;
        }
        let pagesArray = [];
        for (let page = from; page <= to; page++) {
            pagesArray.push(page);
        }
        return pagesArray;
    }
    changePage(page) {
        this.pagination.current_page = page;
        this.$emit('paginate', page);
    }
    get shouldShowPagination() {
        return this.pagination.total > this.pagination.last_page * this.pagination.per_page;
    }
};
__decorate([
    Prop()
], Pagination.prototype, "pagination", void 0);
__decorate([
    Prop()
], Pagination.prototype, "offset", void 0);
Pagination = __decorate([
    Component
], Pagination);
export default Pagination;
