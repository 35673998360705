import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
// Services
import PackageService from '@/services/packages';
// Components
import Btn from '@/components/Button/Btn.vue';
import PackageCard from '@/views/Packages/Components/PackageCard/PackageCard.vue';
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
import CoverImage from '@/components/Elements/CoverImage.vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import SelectInput from '@/components/FormInputs/SelectInput/SelectInput.vue';
let SpecialOffers = class SpecialOffers extends Vue {
    constructor() {
        super(...arguments);
        this.packages = [];
        this.currencyOptions = [];
        this.currency = null;
        this.currencies = [];
        this.loading = true;
        this.paginationLoaded = false;
        this.onlySpecialOffers = false;
        this.coverImageDesc = '';
        this.cardIcon = ['fal', 'ellipsis-v'];
    }
    created() {
    }
    async mounted() {
        await this.$store.commit('packages/SET_EMPTY');
        await this.$store.commit('packages/SET_EMPTY');
        await this.$store.commit('stories/SET_EMPTY');
        await this.$store.commit('projects/SET_EMPTY');
        await this.$store.commit('discussions/SET_EMPTY');
        await this.$store.commit('wishlists/SET_EMPTY');
        await this.resetPackageValues();
        // Get only special offers
        await this.$store.dispatch('packages/setOnlySpecialOffers', true);
        this.onlySpecialOffers = this.$store.getters['packages/getOnlySpecialOffers'];
        if (this.$route.query.page) {
            await this.$store.dispatch('packages/setPage', this.$route.query.page);
        }
        this.getPackages();
        // Setup the currency selector
        await this.$store.dispatch('app/getCurrencies');
        this.currencies = this.$store.getters['app/currencies'];
        this.currencyOptions = this.currencies?.map((currency) => {
            return {
                name: `${currency.code} - ${currency.name}`,
                value: currency.code,
                selected: false,
            };
        });
        if (this.$store.getters['app/selectedCurrency'] !== null) {
            this.currency = this.$store.getters['app/selectedCurrency'].code;
        }
        this.paginationLoaded = true;
        this.loading = false;
    }
    async getPackages() {
        try {
            const response = await PackageService.get();
            this.packages = response.data.packages;
            this.$store.dispatch('pagination/setPagination', response.data.pagination);
            this.$store.dispatch('user/resetSingleResourceCount', 'packages');
        }
        catch (e) {
            this.$store.dispatch('notification/showError', e.response.data.message);
        }
    }
    async beforeDestroy() {
        await this.resetPackageValues();
    }
    async resetCurrency() {
        this.currency = null;
        await this.$store.dispatch('app/setCurrency', null);
    }
    async resetPackageValues() {
        await this.$store.commit('packages/SET_ONLY_FAVOURITES', false);
        await this.$store.commit('packages/SET_ONLY_NON_MALARIA', false);
        await this.$store.dispatch('packages/setFilter', '');
        await this.$store.dispatch('packages/setPage', 1);
        await this.$store.dispatch('packages/setOnlySpecialOffers', false);
    }
    async paginate(page) {
        await this.$store.dispatch('packages/setPage', page);
        var query = {};
        query['page'] = page.toString();
        if (this.onlySpecialOffers) {
            query['only_special_offers'] = this.onlySpecialOffers;
        }
        this.$router.push({
            name: 'packages',
            query: query,
        });
    }
};
__decorate([
    Getter('pagination', { namespace: 'pagination' })
], SpecialOffers.prototype, "pagination", void 0);
SpecialOffers = __decorate([
    Component({
        components: {
            Btn,
            PackageCard,
            TextInput,
            CoverImage,
            SelectInput,
            Pagination,
        },
        metaInfo() {
            return {
                title: 'Africa Geographic | special offers',
                meta: [
                    {
                        name: 'description',
                        content: 'Africa Geographic special offers',
                    },
                ],
                link: [{ rel: 'canonical', href: `${process.env.VUE_APP_URL}/special-offers` }],
            };
        },
    })
], SpecialOffers);
export default SpecialOffers;
