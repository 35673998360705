import { __decorate } from "tslib";
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
let SelectInput = class SelectInput extends Vue {
};
__decorate([
    PropSync('value', { type: String })
], SelectInput.prototype, "returnValue", void 0);
__decorate([
    Prop()
], SelectInput.prototype, "id", void 0);
__decorate([
    Prop()
], SelectInput.prototype, "label", void 0);
__decorate([
    Prop()
], SelectInput.prototype, "placeholder", void 0);
__decorate([
    Prop()
], SelectInput.prototype, "options", void 0);
__decorate([
    Prop()
], SelectInput.prototype, "error", void 0);
__decorate([
    Prop({ default: false })
], SelectInput.prototype, "required", void 0);
SelectInput = __decorate([
    Component
], SelectInput);
export default SelectInput;
